import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import Button from "components/Button"

const Content = () => {
  const { t } = useLocale()

  return (
    <section className="not-found-content">
      <div className="container-fluid">
        <div className="not-found-content__bg"></div>
        <span>404</span>
        <h3>{t("Strona o podanym adresie nie istnieje")}</h3>
        <div className="not-found-content__slogan">
          <img
            src={require("assets/images/home/hero-slogan.svg").default}
            alt=""
          />
        </div>
        <div className="not-found-content__cta">
          <Button to={t("/")}>{t("Strona główna")}</Button>
        </div>
      </div>
    </section>
  )
}

export default Content
