import React from "react"

import Layout from "components/Layout"

import { Content } from "page_components/not-found"

const NotFoundPage = ({ location }) => {
  return (
    <Layout
      seo={{
        title: "404",
      }}
      location={location}
      rmFooter
    >
      <Content />
    </Layout>
  )
}

export default NotFoundPage
